<template>
	<section :class="['default-layout', { 'no-nav': $route.meta.hideNav }]">
		<StsHero :image="image" :logo="logo" :logoTitle="name" :overflow="overflow">
			<PortalTarget name="hero" />
		</StsHero>

		<main id="main-content" :class="{ 'pad-top': overflow }">
			<section class="content-container">
				<router-view :applicant="applicant" />
			</section>
		</main>

	</section>
</template>





<script>
import { PortalTarget } from 'portal-vue';
import { mapState } from 'vuex';
import { StsHero, StsDisclaimer } from '@/components/SwitchThink';

export default {
	name: 'DefaultLayout',
	components: {
		PortalTarget,
		StsHero,
		StsDisclaimer,
		EHOLogo: () => import('@/components/SwitchThink/logos').then(({ EHOLogo }) => EHOLogo)
	},
	props: {
		overflow: {
			type: Boolean,
			required: false
		}
	},
	computed: {
		...mapState('branding', {
			logo: state => state.images.logo,
			name: state => state.name
		}),
		...mapState('pageOptions', { image: state => state.currentHeader }),
		...mapState('applicant', { applicant: state => state }),
		footer() {
			return this.$route.meta.showFooter;
		}
	}
};
</script>




<style lang="scss" scoped>
.default-layout {
	position: relative;
	min-height: 100vh;
	padding-top: 50px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	&.no-nav {
		padding-top: 0;
	}

	main {
		flex: 1;
		justify-content: center;
		box-sizing: border-box;
		position: relative;
		width: 100%;
		padding: 1.5rem;
		margin: 0 auto;

		&.pad-top {
			padding-top: 8.5rem;
		}
	}
}

    #main-content {
        background-color: var(--background);
    }


@media only screen and (min-width: 850px) {
	.default-layout {
		header {
			position: fixed;
			width: 35vw;
		}

		main {
			display: flex;
			flex-direction: column;
			margin: 0;
			margin-left: 35vw;
			width: auto;

			&.pad-top {
				padding-top: 0;
			}
		}
	}
}

@media only screen and (min-width: 1525px) {
	.default-layout main .content-container {
		max-width: 85%;
		width: 100%;
		margin: 0 auto;
	}
}

@media only screen and (min-width: 1920px) {
	.default-layout main .content-container {
		max-width: 75%;
	}
}
</style>